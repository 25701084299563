<template>
  <common-dialog
    :show="show"
    :loading="loading"
    @close="close"
  >
    <template #header>
      <span>Добавление платежного поручения</span>
    </template>
    <template #body>
      <payment-order-form
        v-model="state"
        :banks="banks"
        :owner-identity.sync="ownerIdentity"
        :is-more-than-nine-characters.sync="isMoreThanNineCharacters"
        :available-payment-modes="availablePaymentModes"
        :payment-types="paymentTypes"
        :currencies="currencies"
        :disabled-fields="[]"
      />
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-5">
        <v-btn
          elevation="0"
          color="#EDEDED"
          class="mr-2"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          elevation="0"
          @click="submit"
        >
          Добавить
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {BAD_REQUEST_ERROR} from "@/constants/messages";
import {
  getAvailablePaymentModes, isValidPaymentOrderForm,
  paymentOrderDefaultState
} from "@/helpers/payment-order";
import PaymentOrderForm from "@/components/forms/payment-order-form.vue";
import CommonDialog from "@/components/shared/common-dialog.vue";
import {fetchPaymentOrdersInjector} from "@/helpers/injectors";

export default {
  components: {CommonDialog, PaymentOrderForm},
  mixins: [closeOnEscapeMixin, filterBySearchFieldMixin],
  inject: {
    fetchPaymentOrders: {
      from: fetchPaymentOrdersInjector
    }
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      loading: false,
      banks: [],
      paymentTypes: [],
      state: paymentOrderDefaultState(),
      ownerIdentity: null,
      isMoreThanNineCharacters: false
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      user: "auth/getUser",
      currencies: "catalogs/getNsiCurrencies",
      paymentModes: "catalogs/getNsiPaymentModes"
    }),
    remains() {
      return this.state.remains ?? 0
    },
    availablePaymentModes() {
      return this.getAvailablePaymentModes(this.paymentModes)
    }
  },
  created() {
    this.loadBanks()
    this.fetchNsiPaymentTypes()
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
    loadBanks() {
      this.$store.dispatch('catalogs/loadBankBIC').then((banks) => this.banks = banks)
    },
    getAvailablePaymentModes,
    fetchNsiPaymentTypes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.paymentTypes = res.data.list.map((i) => ({...i, search: `${i.code}-${i.name}`}))
      })
    },
    submit() {
      if (!isValidPaymentOrderForm.call(this, {
        paymentOrderState: this.state,
        ownerIdentity: this.ownerIdentity
      })) return;
      const payload = convertEmptyStringsToNull({
        ...this.state,
        unp: this.isMoreThanNineCharacters ? null : this.ownerIdentity,
        personal_number: this.isMoreThanNineCharacters ? this.ownerIdentity : null,
        remains: this.state.sum,
        division_id: this.user.user.activeDivisionId
      })
      this.setLoading(true)
      this.$store.dispatch('dt/createPaymentOrder', payload)
        .then(() => this.$info("ПП создано"))
        .then(() => this.fetchPaymentOrders())
        .then(() => this.close())
        .catch(this.showErrorMessage)
        .finally(() => this.setLoading(false))
    },
    showErrorMessage(err) {
      const message = +err.response.status === 400 && err.response.data?.error !== BAD_REQUEST_ERROR
        ? err.response.data
        : "Не удалось создать ПП"
      this.$error(message)
    },
    clearState() {
      Object.assign(this.$data.state, paymentOrderDefaultState())
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>
